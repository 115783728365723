exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-case-studies-tsx": () => import("./../../../src/templates/caseStudies.tsx" /* webpackChunkName: "component---src-templates-case-studies-tsx" */),
  "component---src-templates-case-study-tsx": () => import("./../../../src/templates/caseStudy.tsx" /* webpackChunkName: "component---src-templates-case-study-tsx" */),
  "component---src-templates-confirmation-tsx": () => import("./../../../src/templates/confirmation.tsx" /* webpackChunkName: "component---src-templates-confirmation-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-product-details-tsx": () => import("./../../../src/templates/productDetails.tsx" /* webpackChunkName: "component---src-templates-product-details-tsx" */),
  "component---src-templates-products-all-tsx": () => import("./../../../src/templates/productsAll.tsx" /* webpackChunkName: "component---src-templates-products-all-tsx" */),
  "component---src-templates-products-by-brand-device-type-tsx": () => import("./../../../src/templates/productsByBrandDeviceType.tsx" /* webpackChunkName: "component---src-templates-products-by-brand-device-type-tsx" */),
  "component---src-templates-products-by-device-tsx": () => import("./../../../src/templates/productsByDevice.tsx" /* webpackChunkName: "component---src-templates-products-by-device-tsx" */),
  "component---src-templates-products-by-device-type-tsx": () => import("./../../../src/templates/productsByDeviceType.tsx" /* webpackChunkName: "component---src-templates-products-by-device-type-tsx" */),
  "component---src-templates-products-by-industry-tsx": () => import("./../../../src/templates/productsByIndustry.tsx" /* webpackChunkName: "component---src-templates-products-by-industry-tsx" */),
  "component---src-templates-products-by-series-tsx": () => import("./../../../src/templates/productsBySeries.tsx" /* webpackChunkName: "component---src-templates-products-by-series-tsx" */),
  "component---src-templates-quote-tsx": () => import("./../../../src/templates/quote.tsx" /* webpackChunkName: "component---src-templates-quote-tsx" */),
  "component---src-templates-vertical-tsx": () => import("./../../../src/templates/vertical.tsx" /* webpackChunkName: "component---src-templates-vertical-tsx" */)
}

